import { useEffect, useRef, useState } from "react";
import GoodJobFeedbackBox from "./FeedbackBox/GoodJobFeedbackBox";
import PythonStoryView from "./PythonStoryView";
import StoryView from "./StoryView";

export default function Story(props) {
  const [isStory, setIsStory] = useState(props.strapiData.Story.length !== 0);
  const [story, setStory] = useState(props.strapiData.Story[0]);
  const [storyTitle, setStoryTitle] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState();
  const [urlList, setUrlList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showFinishButton, setShowFinishButton] = useState(false);
  const [feedbackActive, setFeedbackActive] = useState(false);
  const [feedbackShow, setFeedbackShow] = useState(true);
  const [isRendredOnce, setIsRenderedOnce] = useState(false);
  const storyTitleRef = useRef(null);
  useEffect(() => {
    if (props.currentSection === "story") {
      props.teacher && !props.teacherView
        ? (document.title = "CSSoch | Review Story")
        : (document.title = "CSSoch | Story");
      if (isStory && !isRendredOnce) {
        if (!props.statusStorage.hasOwnProperty("story_status")) {
          props.updateStatusStorage({ story_feedback: "unavailable" });
        }
        setMaxPages(story.pages);
        setStoryTitle(story["Story Name"]);
        for (const frame of story.Frames) {
          setUrlList((urlList) => [...urlList, frame.image_url]);
        }
        setIsRenderedOnce(true);
        setLoaded(true);
      }
    }
    setFeedbackActive(false);
  }, [props.currentSection]);

  const handleScroll = () => {
    if (story.is_python_story) storyTitleRef.current.scrollTo(0, 0);
    else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  function handleBigArrowRightClick() {
    handleScroll();
    setCurrentPage((currentPage) => {
      currentPage += 1;
      return currentPage;
    });
    if (
      currentPage + 1 === maxPages &&
      props.statusStorage.story_status !== "completed"
    ) {
      setShowFinishButton(true);
      props.updateStatusStorage({ story_status: "completed" });
    }
  }
  function handleBigArrowLeftClick() {
    handleScroll();
    setCurrentPage((currentPage) => {
      currentPage -= 1;
      return currentPage;
    });
  }
  function handleItemLikedFeedback() {
    props.updateStatusStorage({ story_feedback: 1 });
    setFeedbackShow(false);
  }
  function handleItemDislikedFeedback() {
    props.updateStatusStorage({ story_feedback: 0 });
    setFeedbackShow(false);
  }
  return (
    <div
      id='story-view'
      className='content-tab'
      style={props.currentSection === "story" ? {} : { display: "none" }}
    >
      {!isStory ? (
        <div id='storyunderConstruction'>
          <h1>Page Under Construction...</h1>
        </div>
      ) : (
        <>
          {story.is_python_story ? (
            <PythonStoryView
              urlList={urlList}
              loaded={loaded}
              currentPage={currentPage}
              maxPages={maxPages}
              handleBigArrowLeftClick={handleBigArrowLeftClick}
              handleBigArrowRightClick={handleBigArrowRightClick}
              handleCurrentSectionChange={props.handleCurrentSectionChange}
              showFinishButton={showFinishButton}
              teacher={props.teacher}
              teacherView={props.teacherView}
              setFeedbackActive={setFeedbackActive}
              story={story}
              storyTitle={storyTitle}
              setShowFinishButton={setShowFinishButton}
              storyTitleRef={storyTitleRef}
            />
          ) : (
            <StoryView
              urlList={urlList}
              loaded={loaded}
              currentPage={currentPage}
              maxPages={maxPages}
              handleBigArrowLeftClick={handleBigArrowLeftClick}
              handleBigArrowRightClick={handleBigArrowRightClick}
              handleCurrentSectionChange={props.handleCurrentSectionChange}
              showFinishButton={showFinishButton}
              teacher={props.teacher}
              teacherView={props.teacherView}
              setFeedbackActive={setFeedbackActive}
              story={story}
              storyTitle={storyTitle}
              setShowFinishButton={setShowFinishButton}
            />
          )}
          {feedbackActive && (
            <GoodJobFeedbackBox
              visibility={feedbackActive}
              setVisibility={setFeedbackActive}
              showFeedback={feedbackShow}
              handleItemLikedFeedback={handleItemLikedFeedback}
              handleItemDislikedFeedback={handleItemDislikedFeedback}
              goToNext={props.goToNext}
              Finish={true}
              currentItem={"story"}
              handleCurrentSectionChange={props.handleCurrentSectionChange}
              getNext={props.getNext}
              nextBtnOnClickhandler={() => {
                setFeedbackActive(false);
                return;
              }}
              setShowFinishButton={setShowFinishButton}
            />
          )}
        </>
      )}
    </div>
  );
}
