import { useEffect, useRef, useState } from 'react';
import HorizontalBar from '../../Assets/Images/HorizontalBar.svg';
import GoodJobFeedbackBox from './FeedbackBox/GoodJobFeedbackBox';
import BadJobFeedbackBox from './FeedbackBox/BadJobFeedbackBox';
import PuzzleView from './PuzzleView';

export default function Puzzle(props) {
  const [isLoadig, setIsLoading] = useState(true);
  const [data, setData] = useState({
    levels: props.strapiData.Puzzle,
    numLevels: props.strapiData.Puzzle.length,
  });
  const [currentLevel, setCurrentLevel] = useState(1);
  const [previousLevel, setPreviousLevel] = useState(1);
  const [resourcesList, setResourecesList] = useState([]);
  const [answerChecked, setAnswerChecked] = useState(false);
  const [currentlyClicked, setCurrentlyClicked] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [started, setStarted] = useState(false);
  const [levelData, setLevelData] = useState();
  const [showPuzzleExplanation, setShowPuzzleExplantion] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [disabledSubmitButton, setDisableSubmitButton] = useState(true);
  const [showBadJobFeedback, setShowBadJobFeedback] = useState(false);
  const [showGoodJobFeedback, setShowGoodJobFeedback] = useState(false);
  const [showFeedback, setShowFeedback] = useState(true);
  const [showSolutionButton, setShowSolutionButton] = useState(true);
  const [isRendredOnce, setIsRenderedOnce] = useState(false);
  const explanationRef = useRef(null)
  useEffect(() => {
    if(props.currentSection === 'puzzle') {
      (props.teacher && !props.teacherView) ? document.title = "CSSoch | Review Puzzle" : document.title = "CSSoch | Puzzle";
      if (!isRendredOnce) {
        setupPuzzleStatistics();
        loadResources(data);
      }
    }
    setShowBadJobFeedback(false);
    setShowGoodJobFeedback(false);
  }, [props.currentSection]);
  useEffect(() => {
    if (!isLoadig) {
      updateLevelColors();
      loadLevel(currentLevel);
      setIsRenderedOnce(true);
    }
  }, [isLoadig]);

  useEffect(() => {
    if(showPuzzleExplanation) explanationRef.current.scrollIntoView({ behavior: 'smooth' });
  },[showPuzzleExplanation])
  
  function setupPuzzleStatistics() {
    if (!props.statusStorage.puzzle_levels.length) {
      const puzzleLevels= []
      for (let i = 0; i < data.numLevels; i++) {
        puzzleLevels.push({
          feedback: 'unavailable',
          option: 'unavailable',
          result: 'unavailable',
          status:'incomplete'
        });
      }
      props.updateStatusStorage({'puzzle_levels' : puzzleLevels},'puzzle_levels');
    }
  }
  function loadResources(loadedData) {
    const resources = [];
    const { numLevels } = data;
    const levelsDone = [];
    for (let i = 0; i < numLevels; i++) levelsDone.push(false);
    for (let i = 0; i < numLevels; i++) {
      let Options;
      const optionValues = [];
      Options = loadedData.levels[i].Options;
      if (!Options) {
        Options = [];
      }
      optionValues.push(loadedData.levels[i]['Question Image']);
      for (const item of Object.values(Options)) {
        if (item.includes('https://')) {
          optionValues.push(item);
        } else {
          optionValues.push(item);
        }
      }
      // adding option array to resourceList
      resources.push(optionValues);
      setResourecesList(resources);
      levelsDone[i] = true;
      for (let i = 0; i < levelsDone.length; i++) {
        if (!levelsDone[i]) break;
        if (i === levelsDone.length - 1) {
          setLoaded(true);
          initialize();
        }
      }
    }
  }
  function initialize() {
    let currentLevelTemp = currentLevel;
    for (let i = 0; i < data.numLevels; i++) {
      if (props.statusStorage.puzzle_levels[i]?.status === 'ongoing') {
        currentLevelTemp = i + 1;
        setCurrentLevel(i + 1);
        setPreviousLevel(i + 1);
      }
    }
    const puzzleLevels = [...props.statusStorage.puzzle_levels];
    if (currentLevelTemp === 1 && puzzleLevels[0]?.status !== 'ongoing') {
      for (let i = 0; i < puzzleLevels.length; i++) {
        if (puzzleLevels[i]?.status === 'incomplete') {
          puzzleLevels[i].status = 'ongoing';
          props.updateStatusStorage({ 'puzzle_levels': puzzleLevels });
          setCurrentLevel(i + 1);
          setPreviousLevel(i + 1);
          break;
        }
      }
    }
    setIsLoading(false);
  }
  function updateLevelColors(statuses={...props.statusStorage}) {
    const buttons = document.querySelectorAll('.puzzle-button-holder > button');
    let allComplete = true;
    for (let i = 0; i < buttons.length; i++) {
      if (statuses.puzzle_levels[i]?.status === 'completed') buttons[i].style.backgroundColor = '#25A596';
      else {
        allComplete = false;
        if (statuses.puzzle_levels[i]?.status === 'ongoing') buttons[i].style.backgroundColor = '#AECB2A';
        else buttons[i].style.backgroundColor = '#EFEFEF';
      }
    }
    if (allComplete && statuses.puzzle_status !== 'completed') {
      statuses['puzzle_status'] = 'completed';
      props.updateStatusStorage(statuses);
    } else if(statuses.puzzle_status === 'incomplete') {
      statuses['puzzle_status'] = 'ongoing';
      props.updateStatusStorage(statuses);
    }
  }
  function loadLevel(level) {
    setShowBadJobFeedback(false);
    setShowGoodJobFeedback(false);
    setShowSolutionButton(true);
    if (
      props.statusStorage.puzzle_levels[level - 1].feedback
      === 'unavailable'
    ) setShowFeedback(true);
    else setShowFeedback(false);
    setShowSubmitButton(true);
    setShowPuzzleExplantion(false);
    setAnswerChecked(false);
    setStarted(true);
    if (previousLevel === level && started) return;
    setCurrentlyClicked(-1);
    setDisableSubmitButton(true);
    const puzzleLevels = [...props.statusStorage.puzzle_levels];
    if (props.statusStorage?.puzzle_status !== "completed" && puzzleLevels[level - 1]?.status !== 'ongoing') {
      for (let i = 0; i < puzzleLevels.length; i++) {
        if (puzzleLevels[i]?.status !== 'completed') {
          puzzleLevels[i].status = 'incomplete';
        }
      }
      if (puzzleLevels[level - 1]?.status !== 'completed') {
        puzzleLevels[level - 1].status = 'ongoing';
      }
      props.updateStatusStorage({ 'puzzle_levels': puzzleLevels });
    }
    if (!props.teacher || (props.teacher && props.teacherView)) updateLevelColors();
    setPreviousLevel(level);
    setLevelData(data.levels[level - 1]);
    if (props.statusStorage.puzzle_levels[level - 1]?.status === 'completed') {
      displayAnswers(
        props.statusStorage.puzzle_levels[level - 1].option,
        false,
        false,
        level,
      );
    }
  }
  function displayAnswers(
    clicked,
    immediate,
    answerCheckedTemp = answerChecked,
    currentLevelTemp = currentLevel,
  ) {
    if (!loaded) return;
    if (answerCheckedTemp) return;
    setShowSubmitButton(false);
    setShowSolutionButton(false);
    const statuses = {...props.statusStorage};
    function scrollToBottom() {
      // Get the height of the entire document
      const docHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
      );
      // Scroll to the bottom of the page
      window.scrollTo(0, docHeight);
    }
    if (immediate) scrollToBottom();
    setShowPuzzleExplantion(true);
    setAnswerChecked(true);
    if (statuses.puzzle_levels[currentLevelTemp - 1]?.status !== 'completed') {
      statuses.exercise_puzzle_levels_completed++;
      statuses.total_puzzle_levels_completed++;
    }
    statuses.puzzle_levels[currentLevelTemp - 1].status = 'completed';
    updateLevelColors(statuses);
    const letters = ['A', 'B', 'C', 'D'];
    if (immediate) {
      if (data.levels[currentLevelTemp - 1].correct === letters[clicked - 1]) {
        setShowGoodJobFeedback(true);
        statuses.puzzle_levels[currentLevelTemp - 1].result = 1;
      } else {
        setShowBadJobFeedback(true);
        statuses.puzzle_levels[currentLevelTemp - 1].result = 0;
      }
      statuses.puzzle_levels[currentLevelTemp - 1].option = clicked;
    }
    props.updateStatusStorage(statuses);
  }
  function submitAnswer() {
    if (props.teacher && !props.teacherView) return;
    if (!loaded) return;
    let level = currentlyClicked;
    if (level > 3) level -= 4;
    level += 1;
    displayAnswers(level, true);
  }
  function handleItemLikedFeedback() {
    const puzzleLevels = [...props.statusStorage.puzzle_levels];
    puzzleLevels[currentLevel - 1].feedback = 1;
    props.updateStatusStorage({'puzzle_levels' : puzzleLevels});
    setShowFeedback(false);
  }
  function handleItemDislikedFeedback() {
    const puzzleLevels = [...props.statusStorage.puzzle_levels];
    puzzleLevels[currentLevel - 1].feedback = 0;
    props.updateStatusStorage({'puzzle_levels' : puzzleLevels});
    setShowFeedback(false);
  }
  function onFeedbackNextBtnClick() {
    setShowBadJobFeedback(false)
    setShowGoodJobFeedback(false)
    if(currentLevel===data.numLevels) return;
    setCurrentLevel(currentLevel + 1);
    loadLevel(currentLevel + 1);
  }
  return (
    <>
      {!isLoadig ? (
        <div id="puzzle-container" className="content-tab d-hidden" style={props.currentSection === 'puzzle' ? {} : { display: 'none' }}>
          <div className="puzzle-button-holder">
            {props.strapiData.Puzzle.map((lvl, index) => {
              if (index === props.strapiData.Puzzle.length - 1) {
                return (
                  <button
                    key={`btn-${index}`}
                    className={currentLevel === index + 1 ? "active" : ""}
                    onClick={() => {
                      setCurrentLevel(index + 1);
                      loadLevel(index + 1);
                    }}
                  >
                    {index + 1}
                  </button>
                );
              }
              return (
                <>
                  <button
                    key={`btn-${index}`}
                    className={currentLevel === index + 1 ? "active" : ""}
                    onClick={() => {
                      setCurrentLevel(index + 1);
                      loadLevel(index + 1);
                    }}
                  >
                    {index + 1}
                  </button>
                  <img key={`img-${index}`} src={HorizontalBar} />
                </>
              );
            })}
          </div>
          {resourcesList[currentLevel - 1][1] === null ? (
            <div id="puzzleunderConstruction">
              <h1>Page Under Construction...</h1>
            </div>
          ) : (
              <PuzzleView
                puzzleStrapiData={data.levels[currentLevel - 1]}
                puzzleData={props.statusStorage.puzzle_levels[currentLevel - 1]}
                submitAnswer={submitAnswer}
                isTeachModule={props.teacherView}
                puzzle_type={"exercise"}
                displaySolution={() => {
                    displayAnswers(
                      data.levels[currentLevel - 1].correct,
                      false,
                    );
                  }
                }
                answerChecked={answerChecked}
                explanationRef={explanationRef}
                currentlyClicked={currentlyClicked}
                setCurrentlyClicked={setCurrentlyClicked}
                disabledSubmitButton={disabledSubmitButton}
                setDisableSubmitButton={setDisableSubmitButton}
                showPuzzleExplanation={showPuzzleExplanation}
                module_type={"module"}
              />
          )}
          {showBadJobFeedback && (
            <BadJobFeedbackBox
              visibility={showBadJobFeedback}
              setVisibility={setShowBadJobFeedback}
              showFeedback={showFeedback}
              handleItemLikedFeedback={handleItemLikedFeedback}
              handleItemDislikedFeedback={handleItemDislikedFeedback}
              currentItem={"puzzle"}
              handleCurrentSectionChange={props.handleCurrentSectionChange}
              getNext={props.getNext}
              goToNext={props.goToNext}
              Finish={currentLevel === data.numLevels}
              nextBtnOnClickhandler={onFeedbackNextBtnClick}
            />
          )}
          {showGoodJobFeedback && (
            <GoodJobFeedbackBox
              visibility={showGoodJobFeedback}
              setVisibility={setShowGoodJobFeedback}
              showFeedback={showFeedback}
              handleItemLikedFeedback={handleItemLikedFeedback}
              handleItemDislikedFeedback={handleItemDislikedFeedback}
              currentItem={"puzzle"}
              handleCurrentSectionChange={props.handleCurrentSectionChange}
              getNext={props.getNext}
              goToNext={props.goToNext}
              Finish={currentLevel === data.numLevels}
              nextBtnOnClickhandler={onFeedbackNextBtnClick}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
