import React, { useContext, useEffect, useState } from "react";
import { NotebookContext } from "../../Contexts/NotebookContext";
import PuzzleView from "../../ModuleComponents/PuzzleView";

function PuzzleCell(props) {
  const { notebookData, uploadNotebookData, isTeachModule, isTeacherSeeingStudentWork } = useContext(NotebookContext);
  const { puzzleCellData, cellIndex, notebookType } = props;
  const [currentlyClicked, setCurrentlyClicked] = useState(-1);
  const [disabledSubmitButton, setDisableSubmitButton] = useState(true);
  const [answerChecked, setAnswerChecked] = useState(false);
  const [puzzleData, setPuzzleData] = useState();
  const [showPuzzleExplanation, setShowPuzzleExplantion] = useState(false);
  const options = ["A", "B", "C", "D"];

  useEffect(() => {
    if (notebookData) {
      if (
        notebookData[`${notebookType}_levels`] &&
        notebookData[`${notebookType}_levels`][cellIndex]
      ) {
        const cellData = {...notebookData[`${notebookType}_levels`][cellIndex]}
        setPuzzleData(cellData);
        setCurrentlyClicked(
          cellData?.option - 1
        );
      } else {
        setPuzzleData({
          type: "Puzzle",
          status: "incomplete",
          option: -1,
          result: "",
        });
      }
    }
  }, [notebookData]);

  function submitAnswer() {
    if (isTeacherSeeingStudentWork) return;
    if (answerChecked) return;
    setShowPuzzleExplantion(true);
    setAnswerChecked(true);
    puzzleData.option = currentlyClicked + 1;
    puzzleData.status = "completed";
    puzzleData.result =
      puzzleCellData?.correct === options[currentlyClicked]
        ? "Correct"
        : "Incorrect";

    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleData,
      },
    };
    if (puzzleData.status === "completed") {
      uploadData[[`${notebookType}_puzzle_levels_completed`]] = notebookData[
        `${notebookType}_puzzle_levels_completed`
      ]
        ? notebookData[`${notebookType}_puzzle_levels_completed`] + 1
        : 1;
      uploadData[[`${notebookType}_levels_completed`]] = notebookData[
        `${notebookType}_levels_completed`
      ]
        ? notebookData[`${notebookType}_levels_completed`] + 1
        : 1;
    }
    uploadNotebookData(uploadData);
  }

  function displaySolution() {
    setAnswerChecked(true);
    setShowPuzzleExplantion(true);
    puzzleData.option = options.indexOf(puzzleCellData?.correct) + 1;
    puzzleData.status = "completed";
    puzzleData.result = "Correct"
    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleData,
      },
    };
    if (puzzleData.status === "completed") {
      uploadData[[`${notebookType}_puzzle_levels_completed`]] = notebookData[
        `${notebookType}_puzzle_levels_completed`
      ]
        ? notebookData[`${notebookType}_puzzle_levels_completed`] + 1
        : 1;
      uploadData[[`${notebookType}_levels_completed`]] = notebookData[
        `${notebookType}_levels_completed`
      ]
        ? notebookData[`${notebookType}_levels_completed`] + 1
        : 1;
    }
    uploadNotebookData(uploadData);
  }

  function giveupLevel() {
    puzzleData["status"] = "completed";
    puzzleData["giveup"] = 1;
    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleData,
      },
      'is_puzzle_level_giveup': 1
    };
    if (puzzleData.status === "completed") {
      uploadData[[`${notebookType}_puzzle_levels_completed`]] = notebookData[
        `${notebookType}_puzzle_levels_completed`
      ]
        ? notebookData[`${notebookType}_puzzle_levels_completed`] + 1
        : 1;
      uploadData[[`${notebookType}_levels_completed`]] = notebookData[
        `${notebookType}_levels_completed`
      ]
        ? notebookData[`${notebookType}_levels_completed`] + 1
        : 1;
    }
    uploadNotebookData(uploadData);
  }

  return (
    <PuzzleView
      puzzleStrapiData={puzzleCellData}
      puzzleData={puzzleData}
      submitAnswer={submitAnswer}
      isTeachModule={isTeachModule}
      puzzle_type={notebookType}
      displaySolution={displaySolution}
      giveupLevel={giveupLevel}
      answerChecked={answerChecked}
      currentlyClicked={currentlyClicked}
      setCurrentlyClicked={setCurrentlyClicked}
      disabledSubmitButton={disabledSubmitButton}
      setDisableSubmitButton={setDisableSubmitButton}
      showPuzzleExplanation={showPuzzleExplanation}
    />
  );
}

export default PuzzleCell;
