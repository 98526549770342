import { useEffect, useRef, useState } from "react";
import Game from "gameslib";
import Confetti from "react-confetti";
import GoodJobFeedbackBox from "./FeedbackBox/GoodJobFeedbackBox";
import BadJobFeedbackBox from "./FeedbackBox/BadJobFeedbackBox";
import ErrorBox from "./FeedbackBox/ErrorBox";
import kidsCheering from "../../Assets/sounds/kids_cheering.mp3";
import { loadGamefromLib } from "../../Utils/LoadGamefromLib";
import { carryForwardPreviousLevelCode } from "../../Utils/CarryForwardCode";
import LevelButtons from "./Assessment/LevelButtons";
import AssessmentStartScreen from "./Assessment/AssessmentStartScreen";
import FinishScreen from "./Assessment/FinishScreen";
import AssessmentGame from "./Assessment/AssessmentGame";
import AssessmentPuzzle from "./Assessment/AssessmentPuzzle";
import AssessmentPython from "./Assessment/AssessmentPython";

function arrow_keys_handler(e) {
  if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
    e.preventDefault();
  }
}
export default function Assesment(props) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [assesmentGamesetInfo, setAssesmentGamesetInfo] = useState();
  const [currentLevel, setCurrentLevel] = useState(1);
  const [showTestScreen, setShowTestScreen] = useState(false);
  const [showStartScreen, setShowStartScreen] = useState(false);
  const [showFinishScreen, setShowFinishScreen] = useState(false);
  const [gameScore, setGameScore] = useState(null);
  const [puzzleScore, setPuzzleScore] = useState(null);
  const [pythonScore, setPythonScore] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [instructionBoxHeight, setInstructionBoxHeight] = useState(64.6262);
  const [codingGameSubmitButtonText, setCodingGameSubmitButtonText] =
    useState("Run Code");
  const [previousLevel, setPreviousLevel] = useState(1);
  const [assesmentRunGameObj, setAssesmentRunGameObj] = useState();
  const [showGiveupButton, setShowGiveUpButton] = useState(false);
  const [showErrorFeedbackBox, setShowErrorFeedbackBox] = useState(false);
  const [showGoodJobFeedbackBox, setShowGoodJobFeedbackBox] = useState(false);
  const [showBadJobFeedbackBox, setShowBadJobFeedbackBox] = useState(false);
  const [nextButtonText, setNextButtonText] = useState("Next");
  const [sucessMsg, setSucessMsg] = useState("That was great!");
  const [resourcesList, setResourecesList] = useState([]);
  const [answerChecked, setAnswerChecked] = useState(false);
  const [currentlyClicked, setCurrentlyClicked] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [showPuzzleExplanation, setShowPuzzleExplantion] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [disabledSubmitButton, setDisableSubmitButton] = useState(true);
  const [runStartTest, setRunStartTest] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [prevClicked, setPrevClicked] = useState(false);
  const [pageIsRendered, setPageIsRendered] = useState(false);
  const [showFeedback, setShowFeedback] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const assesmentDiv = useRef(null);
  const [isRendredOnce, setIsRenderedOnce] = useState(false);
  const [showAssesmentNotCompleted, setShowAssesmentNotCompleted] =
    useState(false);
  const [currentLevelJson, setCurrentLevelJson] = useState();
  const [showSolutionButton, setShowSolutionButton] = useState(true);
  const [showBlocksExceedMsg, setShowBlocksExceedMsg] = useState(false);
  const [levelsJson, setLevelsJson] = useState([]);
  const [codeCarryForwardLevels, setCodeCarryForwardLevels] = useState([]);
  const [pythonStartingFromLevelNumber, setPythonStartingFromLevelNumber] =
    useState(null);
  const [expanded, setExpanded] = useState(true);
  
  useEffect(() => {
    if (props.currentSection === "assessment") {
      props.teacher && !props.teacherView
        ? (document.title = "CSSoch | Review Assessment")
        : (document.title = "CSSoch | Assessment");
      if (!isRendredOnce) initialize();
      else if (
        assesmentRunGameObj &&
        assesmentGamesetInfo.options.structure === "codingEnvironment" && currentLevel && props.statusStorage?.assessment_levels[currentLevel - 1]?.type === "game"
      ){
        assesmentRunGameObj.getBlocksCount();
      }
    }
    setShowBadJobFeedbackBox(false);
    setShowGoodJobFeedbackBox(false);
    setShowErrorFeedbackBox(false);
    setShowBlocksExceedMsg(false);
  }, [props.currentSection]);
  useEffect(() => {
    if (assesmentGamesetInfo) {
      if (!assesmentRunGameObj) {
        setAssesmentRunGameObj(new Game(assesmentGamesetInfo.gameType));
      }
      initializeAssessment();
    }
  }, [assesmentGamesetInfo]);
  useEffect(() => {
    if (!isLoading) {
      if (showTestScreen) {
        if (assesmentRunGameObj && !isRendredOnce) {
          assesmentRunGameObj.setup(assesmentGamesetInfo.options, true);
          assesmentRunGameObj.loadBlocklyImgs(
            assesmentGamesetInfo.blocklyAsset
          );
          assesmentRunGameObj.loadAssets(assesmentGamesetInfo.assets);
          setIsRenderedOnce(true);
        }
        let tempCurrentLevel = currentLevel;
        if (
          tempCurrentLevel === 1 &&
          props.statusStorage.assessment_levels[0]?.status !== "ongoing"
        ) {
          for (let i = 0; i < data.numLevels; i++) {
            if (
              props.statusStorage.assessment_levels[i]?.status === "completed"
            )
              tempCurrentLevel = i + 1;
          }
        }
        setCurrentLevel(tempCurrentLevel);
        setPreviousLevel(tempCurrentLevel);
        setRunStartTest(true);
        return () => {
          window.removeEventListener("keydown", arrow_keys_handler, false);
          assesmentRunGameObj?.restart();
        };
      }
    }
  }, [isLoading, assesmentRunGameObj]);
  useEffect(() => {
    if (runStartTest) {
      startTest();
    }
  }, [runStartTest]);
  useEffect(() => {
    if (isLoaded) {
      if (prevClicked) {
        loadLevel(currentLevel);
        setPrevClicked(false);
      } else loadLevel(previousLevel + 1);
    }
  }, [currentLevel, isLoaded]);

  function initialize() {
    const assessmentGameLevels = props.strapiData.Assessment["Game Set"];
    if (assessmentGameLevels.length) {
      const gameSet = assessmentGameLevels[0];
      const fetchPromises = gameSet?.Levels?.map((level) =>
        fetch(level["Config File"]).then((res) => res.json())
      );
      Promise.all(fetchPromises)
        .then((results) => {
          results.map((json, index) => {
            if (json.hasOwnProperty("carryForwardCode"))
              setCodeCarryForwardLevels((prevLevels) => [
                ...prevLevels,
                index + 1,
              ]);
          });
          const updatedLevelsJson = [...levelsJson, ...results];
          setLevelsJson(updatedLevelsJson);
          setAssesmentGamesetInfo(loadGamefromLib(gameSet, true, false));
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      initializeAssessment();
    }
  }

  function initializeAssessment() {
    const assesmentLevels = [];
    if (props.strapiData.Assessment["Game Set"].length) {
      for (let i = 0; i < assesmentGamesetInfo?.levels.length; i++) {
        const gLevel = assesmentGamesetInfo?.levels[i];
        gLevel.type = "game";
        assesmentLevels.push(gLevel);
      }
    }
    if (props.strapiData.Assessment.Python.length) {
      setPythonStartingFromLevelNumber(assesmentLevels.length + 1);
      for (
        let i = 0;
        i < props.strapiData.Assessment?.Python?.[0]?.Levels.length;
        i++
      ) {
        const pyLevel = props.strapiData.Assessment.Python?.[0]?.Levels[i];
        pyLevel.type = "python";
        assesmentLevels.push(pyLevel);
      }
    }
    if (props.strapiData.Assessment.Puzzle.length) {
      for (let i = 0; i < props.strapiData.Assessment.Puzzle.length; i++) {
        const pLevel = props.strapiData.Assessment.Puzzle[i];
        pLevel.type = "puzzle";
        assesmentLevels.push(pLevel);
      }
    }
    setData({
      levels: assesmentLevels,
      numLevels: assesmentLevels.length,
    });
    const tempdata = {
      levels: assesmentLevels,
      numLevels: assesmentLevels.length,
    };
    loadResources(tempdata);
    if (
      !props.statusStorage.assessment_levels.length &&
      props.statusStorage.assessment_state !== "notstarted"
    ) {
      props.statusStorage.assessment_levels = [];
      for (let i = 0; i < tempdata.numLevels; i++) {
        if (tempdata.levels[i].type === "puzzle") {
          props.statusStorage.assessment_levels.push({
            feedback: "unavailable",
            option: "unavailable",
            result: "unavailable",
            giveup: 0,
            status: "incomplete",
            type: "puzzle",
          });
        } else if (tempdata.levels[i].type === "game") {
          props.statusStorage.assessment_levels.push({
            feedback: "unavailable",
            code: "",
            attempts: 0,
            giveup: 0,
            status: "incomplete",
            type: "game",
          });
        } else {
          props.statusStorage.assessment_levels.push({
            feedback: "unavailable",
            code: "",
            attempts: 0,
            giveup: 0,
            status: "incomplete",
            test_cases: [],
            type: "python",
          });
        }
      }
    }
    // Check whether test has already been started or not
    if (props.statusStorage.assessment_state === "started") {
      if (!props.teacher) {
        setShowTestScreen(true);
      } else {
        setShowAssesmentNotCompleted(true);
      }
      setShowFinishScreen(false);
      setShowStartScreen(false);
    } else if (props.statusStorage.assessment_state === "notstarted") {
      if (!props.teacher) {
        setShowStartScreen(true);
      } else {
        setShowAssesmentNotCompleted(true);
      }
      setShowTestScreen(false);
      setShowFinishScreen(false);
    } else {
      setViewOnly(true);
      finishAssesment(true);
      if (props.teacher && props.teacherView) {
        setShowFinishScreen(false);
        setShowTestScreen(true);
      } else {
        setShowFinishScreen(true);
        setShowTestScreen(false);
      }
      setShowStartScreen(false);
    }
    setIsLoading(false);
  }
  function startTest(tempCurrentLevel = currentLevel) {
    setRunStartTest(false);
    if (!viewOnly) {
      loadLevel(tempCurrentLevel);
    } else {
      setPreviousLevel(0);
      setCurrentLevel(1);
      tempCurrentLevel = 1;
      setIsLoaded(true);
    }
  }
  function finishAssesment(calculate) {
    if (calculate) {
      const gameScore = [0, 0];
      const puzzleScore = [0, 0];
      const pythonScore = [0, 0];
      const gameLevelScrore = [];
      const puzzleLevelScrore = [];
      const pythonLevelScrore = [];
      let isGameInAssessment = false;
      let isPuzzleInAssessment = false;
      let isPythonInAssessment = false;
      for (let i = 0; i < props.statusStorage.assessment_levels.length; i++) {
        if (props.statusStorage.assessment_levels[i]?.type === "game") {
          isGameInAssessment = true;
          let score = 5;
          let status = ["Correct", "green"];
          if (props.statusStorage.assessment_levels[i].giveup === 1) {
            status = ["NotSolved", "red"];
            score = 0;
          }
          gameScore[0] += score;
          gameScore[1] += 5;
          gameLevelScrore.push({
            num: i + 1,
            color: status[1],
            result: status[0],
            score,
          });
        } else if(props.statusStorage.assessment_levels[i]?.type === "puzzle") {
          isPuzzleInAssessment = true;
          const { result } = props.statusStorage.assessment_levels[i];
          let score = 0;
          if (result === 1) score = 1;
          let status = ["Correct", "green"];
          if (props.statusStorage.assessment_levels[i].giveup === 1)
            status = ["NotSolved", "red"];
          else if (result === 0) status = ["Incorrect", "red"];
          puzzleLevelScrore.push({
            num: i + 1,
            color: status[1],
            result: status[0],
            score,
          });
          puzzleScore[0] += score;
          puzzleScore[1] += 1;
        } else {
          isPythonInAssessment = true;
          let score = 5;
          let status = ["Correct", "green"];
          if (props.statusStorage.assessment_levels[i].giveup === 1) {
            status = ["NotSolved", "red"];
            score = 0;
          }
          pythonScore[0] += score;
          pythonScore[1] += 5;
          pythonLevelScrore.push({
            num: i + 1,
            color: status[1],
            result: status[0],
            score,
          });
        }
      }
      gameLevelScrore.push({
        score: gameScore[0],
        total: gameScore[1],
      });
      puzzleLevelScrore.push({
        score: puzzleScore[0],
        total: puzzleScore[1],
      });
      pythonLevelScrore.push({
        score: pythonScore[0],
        total: pythonScore[1],
      });
      if (isGameInAssessment) props.updateStatusStorage({ game_score: gameLevelScrore });
      if (isPuzzleInAssessment) props.updateStatusStorage({ puzzle_score: puzzleLevelScrore });
      if (isPythonInAssessment) props.updateStatusStorage({ python_score: pythonLevelScrore });
      if (isGameInAssessment) setGameScore(gameLevelScrore);
      if (isPuzzleInAssessment) setPuzzleScore(puzzleLevelScrore);
      if (isPythonInAssessment) setPythonScore(pythonLevelScrore);
    }
  }
  function loadLevel(level) {
    setCurrentLevel(level);
    setShowErrorFeedbackBox(false);
    setShowGoodJobFeedbackBox(false);
    setShowBadJobFeedbackBox(false);
    setShowFeedback(true);
    setCurrentlyClicked(-1);
    setAnswerChecked(false);
    setShowPuzzleExplantion(false);
    setShowSubmitButton(true);
    setNextButtonText("Next");
    setShowSolutionButton(true);
    setDisableSubmitButton(true);
    for (let i = 0; i < data.numLevels; i++) {
      document
        .querySelectorAll(".assesment-button-holder button")
        [i].classList.remove("active");
    }
    document
      .querySelectorAll(".assesment-button-holder button")
      [level - 1].classList.add("active");
    if (
      props.statusStorage.assessment_levels[level - 1].feedback !==
      "unavailable"
    )
      setShowFeedback(false);
    if (data && data.levels[level - 1].type === "game") {
      const boxHeight = 64.6262;
      let textHeight = document.querySelector(
        "#assesment-instructions"
      ).offsetHeight;
      if (boxHeight <= textHeight) {
        textHeight += 20;
        setInstructionBoxHeight(textHeight);
      } else {
        setInstructionBoxHeight(textHeight);
      }
      const assessmentLevels = [...props.statusStorage.assessment_levels];
      if (
        props.statusStorage.assessment_status !== "completed" &&
        assessmentLevels[level - 1]?.status !== "ongoing"
      ) {
        for (let i = 0; i < assessmentLevels.length; i++) {
          if (assessmentLevels[i]?.status !== "completed") {
            assessmentLevels[i].status = "incomplete";
          }
        }
        if (assessmentLevels[level - 1]?.status !== "completed") {
          assessmentLevels[level - 1].status = "ongoing";
          props.updateStatusStorage({ assessment_levels: assessmentLevels });
        }
      }
      if (
        props.statusStorage.assessment_levels[level - 1].attempts >= 10 &&
        assessmentLevels[level - 1]?.status !== "completed"
      )
        setShowGiveUpButton(true);
      else setShowGiveUpButton(false);
      setCodingGameSubmitButtonText("Run Code");
      runGame(levelsJson[level - 1], level);
    } else if (data && data.levels[level - 1].type === "puzzle") {
      setShowGiveUpButton(true);
      if (!loaded) return;
      const assessmentLevels = [...props.statusStorage.assessment_levels];
      for (let i = 0; i < assessmentLevels.length; i++) {
        if (assessmentLevels[i]?.status !== "completed") {
          assessmentLevels[i].status = "incomplete";
        }
      }
      if (assessmentLevels[level - 1]?.status !== "completed") {
        assessmentLevels[level - 1].status = "ongoing";
      }
      props.updateStatusStorage({ assessment_levels: assessmentLevels });
    } else if (data && data.levels[level - 1].type === "python") {
      const assessmentLevels = [...props.statusStorage.assessment_levels];
      if (
        props.statusStorage.assessment_status !== "completed" &&
        assessmentLevels[level - 1]?.status !== "ongoing"
      ) {
        for (let i = 0; i < assessmentLevels.length; i++) {
          if (assessmentLevels[i]?.status !== "completed") {
            assessmentLevels[i].status = "incomplete";
          }
        }
        if (assessmentLevels[level - 1]?.status !== "completed") {
          assessmentLevels[level - 1].status = "ongoing";
          props.updateStatusStorage({ assessment_levels: assessmentLevels });
        }
      }
      if (
        props.statusStorage.assessment_levels[level - 1].attempts >= 10 &&
        assessmentLevels[level - 1]?.status !== "completed"
      )
        setShowGiveUpButton(true);
      else setShowGiveUpButton(false);
    }
    updateLevelColors();
    setPreviousLevel(level);
    window.removeEventListener("keydown", arrow_keys_handler, false);
  }
  function runGame(jsonData, level) {
    setCurrentLevelJson(jsonData);
    assesmentRunGameObj.loadLevel(jsonData);
    if (props.statusStorage.assessment_levels[level - 1].giveup) {
      if (assesmentGamesetInfo.options.structure === "playgroundEnvironment") {
        assesmentRunGameObj.setOnFinishedLoading(() => {
          assesmentRunGameObj.loadSolution();
        });
      } else {
        assesmentRunGameObj.loadSolution();
      }
    }
    if (props.statusStorage.assessment_levels[level - 1].code) {
      if (assesmentGamesetInfo.options.structure === "playgroundEnvironment") {
        assesmentRunGameObj.setOnFinishedLoading(() => {
          const loadedcode =
            props.statusStorage.assessment_levels[level - 1].code;
          assesmentRunGameObj.loadCode(loadedcode.toString());
        });
      } else {
        const loadedcode =
          props.statusStorage.assessment_levels[level - 1].code;
        assesmentRunGameObj.loadCode(loadedcode.toString());
      }
    } else if (
      assesmentGamesetInfo.options.structure === "playgroundEnvironment"
    ) {
      assesmentRunGameObj.setOnFinishedLoading(() => {
        assesmentRunGameObj.loadCode("");
      });
    } else if (
      jsonData.hasOwnProperty("carryForwardCode") &&
      jsonData.carryForwardCode &&
      (props.statusStorage.assessment_levels[currentLevel - 2]?.status ===
        "completed" ||
        jsonData.statusToCarryForwardCode ===
          props.statusStorage.assessment_levels[currentLevel - 2].status)
    ) {
      const previousLevelCode =
        props.statusStorage.assessment_levels[level - 2].code;
      const codeToLoad = carryForwardPreviousLevelCode(
        previousLevelCode,
        assesmentRunGameObj.fetchCode()
      );
      assesmentRunGameObj.loadCode(codeToLoad.toString());
    }
  }
  function onwards() {
    setPageIsRendered(true);
    setShowGoodJobFeedbackBox(false);
    setShowBadJobFeedbackBox(false);
    if (currentLevel === data.numLevels) {
      if (props.statusStorage.assessment_state === "finished") {
        finishAssesment(false);
      } else {
        finishAssesment(true);
        setShowFinishScreen(true);
        setShowTestScreen(false);
        props.updateStatusStorage({ assessment_state: "finished" });
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 5000);
        const audio = new Audio(kidsCheering);
        audio.volume = 0.6;
        audio.play();
      }
    } else {
      setCurrentLevel(currentLevel + 1);
      setIsLoaded(true);
    }
  }
  function updateLevelColors(statuses = { ...props.statusStorage }) {
    const buttons = document.querySelectorAll(
      ".assesment-button-holder button"
    );
    let allComplete = true;
    for (let i = 0; i < buttons.length; i++) {
      if (props.teacher && props.teacherView) {
        buttons[i].style.backgroundColor = "#25A596";
      } else if (statuses.assessment_levels[i]?.status === "completed")
        buttons[i].style.backgroundColor = "#25A596";
      else {
        allComplete = false;
        if (statuses.assessment_levels[i]?.status === "ongoing")
          buttons[i].style.backgroundColor = "#AECB2A";
        else buttons[i].style.backgroundColor = "#EFEFEF";
      }
    }
    if (allComplete && statuses.assessment_status !== "completed") {
      statuses.assessment_status = "completed";
      props.updateStatusStorage(statuses);
    } else if (
      statuses.assessment_status !== "ongoing" &&
      statuses.assessment_status !== "completed"
    ) {
      statuses.assessment_status = "ongoing";
      props.updateStatusStorage(statuses);
    }
  }
  function loadResources(loadedData) {
    const resources = [];
    const { numLevels } = loadedData;
    const levelsDone = [];
    for (let i = 0; i < numLevels; i++) levelsDone.push(false);
    for (let i = 0; i < numLevels; i++) {
      if (loadedData.levels[i].type === "puzzle") {
        let Options;
        const optionValues = [];
        Options = loadedData.levels[i].Options;
        if (!Options) {
          Options = [];
        }
        optionValues.push(loadedData.levels[i]["Question Image"]);
        for (const item of Object.values(Options)) {
          if (item.includes("https://")) {
            optionValues.push(item);
          } else {
            optionValues.push(item);
          }
        }
        // adding option array to resourceList
        resources.push(optionValues);
        setResourecesList(resources);
        levelsDone[i] = true;
        for (let i = 0; i < levelsDone.length; i++) {
          if (!levelsDone[i]) break;
          if (i === levelsDone.length - 1) {
            setLoaded(true);
          }
        }
      } else {
        resources.push([]);
        levelsDone[i] = true;
      }
    }
  }
  function displayAssesmentAnswers(clicked, immediate, level = currentLevel) {
    if (!loaded) return;
    setShowSubmitButton(false);
    setShowPuzzleExplantion(true);
    setShowSolutionButton(false);
    if (immediate) window.scrollTo(0, document.body.scrollHeight);
    setAnswerChecked(true);
    const letters = ["A", "B", "C", "D"];
    // Got answer correct
    if (immediate) {
      let result;
      if (data.levels[level - 1].correct === letters[clicked - 1]) {
        result = 1;
      } else {
        result = 0;
      }
      if (
        (props.statusStorage.assessment_levels[currentLevel - 1]?.status !==
          "completed" &&
          props.statusStorage.assessment_levels[currentLevel - 1].giveup ===
            0) ||
        props.teacherView
      ) {
        const assessmentLevels = [...props.statusStorage.assessment_levels];
        assessmentLevels[currentLevel - 1].option = clicked;
        assessmentLevels[currentLevel - 1].result = result;
        props.updateStatusStorage({ assessment_levels: assessmentLevels });
        if (result === 1) setShowGoodJobFeedbackBox(true);
        else setShowBadJobFeedbackBox(true);
      }
      const statuses = { ...props.statusStorage };
      if (
        statuses.assessment_levels[currentLevel - 1]?.status !== "completed"
      ) {
        statuses["total_puzzle_levels_completed"]++;
        statuses["assessment_puzzle_levels_completed"]++;
      }
      statuses.assessment_levels[currentLevel - 1].status = "completed";
      props.updateStatusStorage(statuses);
      updateLevelColors(statuses);
    }
    if (currentLevel === data.numLevels) setNextButtonText("Finish");
  }
  function handleItemLikedFeedback() {
    const assessmentLevels = [...props.statusStorage.assessment_levels];
    assessmentLevels[currentLevel - 1].feedback = 1;
    props.updateStatusStorage({ assessment_levels: assessmentLevels });
    setShowFeedback(false);
  }
  function handleItemDislikedFeedback() {
    const assessmentLevels = [...props.statusStorage.assessment_levels];
    assessmentLevels[currentLevel - 1].feedback = 0;
    props.updateStatusStorage({ assessment_levels: assessmentLevels });
    setShowFeedback(false);
  }
  return (
    <>
      {!isLoading ? (
        <div
          id="assesment-view"
          className="content-tab d-hidden"
          ref={assesmentDiv}
          style={
            props.currentSection === "assessment" ? {} : { display: "none" }
          }
        >
          {" "}
          {showAssesmentNotCompleted ? (
            <p style={{ fontSize: "2vw", padding: "1.5vw 1vw", margin: 0 }}>
              Assessment not yet complete.
            </p>
          ) : (
            <>
              {props.strapiData.Assessment["Game Set"].length === 0 &&
              props.strapiData.Assessment.Puzzle.length === 0 &&
              props.strapiData.Assessment.Python.length === 0 ? (
                <div id="assesmentunderConstruction">
                  <h1>Page Under Construction...</h1>
                </div>
              ) : (
                <>
                  <div
                    className="test-screen"
                    style={showTestScreen ? {} : { display: "none" }}
                  >
                    <LevelButtons
                      viewOnly={viewOnly}
                      currentLevel={currentLevel}
                      setPrevClicked={setPrevClicked}
                      setCurrentLevel={setCurrentLevel}
                      data={data}
                      codeCarryForwardLevels={codeCarryForwardLevels}
                      teacher={props.teacher}
                      teacherView={props.teacherView}
                      setPageIsRendered={setPageIsRendered}
                      setShowFinishScreen={setShowFinishScreen}
                      setShowTestScreen={setShowTestScreen}
                      statusStorage={props.statusStorage}
                      onwards={onwards}
                      nextButtonText={nextButtonText}
                    />
                    <div className="do-assesment">
                      <AssessmentGame
                        data={data}
                        currentLevel={currentLevel}
                        assesmentGamesetInfo={assesmentGamesetInfo}
                        instructionBoxHeight={instructionBoxHeight}
                        showErrorFeedbackBox={showErrorFeedbackBox}
                        showGoodJobFeedbackBox={showGoodJobFeedbackBox}
                        showBadJobFeedbackBox={showBadJobFeedbackBox}
                        teacherView={props.teacherView}
                        teacher={props.teacher}
                        assesmentRunGameObj={assesmentRunGameObj}
                        currentLevelJson={currentLevelJson}
                        showGiveupButton={showGiveupButton}
                        codingGameSubmitButtonText={codingGameSubmitButtonText}
                        setCodingGameSubmitButtonText={
                          setCodingGameSubmitButtonText
                        }
                        arrow_keys_handler={arrow_keys_handler}
                        statusStorage={props.statusStorage}
                        setShowErrorFeedbackBox={setShowErrorFeedbackBox}
                        setShowBlocksExceedMsg={setShowBlocksExceedMsg}
                        updateStatusStorage={props.updateStatusStorage}
                        updateLevelColors={updateLevelColors}
                        setShowGiveUpButton={setShowGiveUpButton}
                        setNextButtonText={setNextButtonText}
                        setSucessMsg={setSucessMsg}
                        setShowGoodJobFeedbackBox={setShowGoodJobFeedbackBox}
                        setShowBadJobFeedbackBox={setShowBadJobFeedbackBox}
                      />
                      {data.levels[currentLevel - 1].type === "puzzle" ? (
                        <AssessmentPuzzle
                          data={data}
                          currentLevel={currentLevel}
                          showPuzzleExplanation={showPuzzleExplanation}
                          showSubmitButton={showSubmitButton}
                          disabledSubmitButton={disabledSubmitButton}
                          teacherView={props.teacherView}
                          teacher={props.teacher}
                          showSolutionButton={showSolutionButton}
                          displayAssesmentAnswers={displayAssesmentAnswers}
                          statusStorage={props.statusStorage}
                          updateLevelColors={updateLevelColors}
                          setShowGiveUpButton={setShowGiveUpButton}
                          setNextButtonText={setNextButtonText}
                          updateStatusStorage={props.updateStatusStorage}
                          setShowBadJobFeedbackBox={setShowBadJobFeedbackBox}
                          currentlyClicked={currentlyClicked}
                          setCurrentlyClicked={setCurrentlyClicked}
                          setDisableSubmitButton={setDisableSubmitButton}
                          loaded={loaded}
                          answerChecked={answerChecked}
                        />
                      ) : (
                        <></>
                      )}
                      {props?.strapiData?.Assessment?.Python?.length > 0 && (
                        <AssessmentPython
                          data={data}
                          display={
                            data.levels[currentLevel - 1].type === "python"
                          }
                          currentSection={props.currentSection}
                          strapiData={props.strapiData}
                          setShowGoodJobFeedbackBox={setShowGoodJobFeedbackBox}
                          currentLevel={currentLevel}
                          updateStatusStorage={props.updateStatusStorage}
                          teacher={props.teacher}
                          teacherView={props.teacherView}
                          statusStorage={props.statusStorage}
                          assessmentPythonStartingLevelNumber={
                            pythonStartingFromLevelNumber
                          }
                          updateLevelColors={updateLevelColors}
                          showGiveupButton={showGiveupButton}
                          setShowGiveUpButton={setShowGiveUpButton}
                          showErrorFeedbackBox={showErrorFeedbackBox}
                          showGoodJobFeedbackBox={showGoodJobFeedbackBox}
                          showBadJobFeedbackBox={showBadJobFeedbackBox}
                          setShowBadJobFeedbackBox={setShowBadJobFeedbackBox}
                          setNextButtonText={setNextButtonText}
                          expanded={expanded}
                          setExpanded={setExpanded}
                        />
                      )}
                    </div>
                  </div>
                  {showStartScreen && (
                    <AssessmentStartScreen
                      updateStatusStorage={props.updateStatusStorage}
                      pageIsRendered={pageIsRendered}
                      assesmentGamesetInfo={assesmentGamesetInfo}
                      setAssesmentRunGameObj={setAssesmentRunGameObj}
                      Game={Game}
                      setViewOnly={setViewOnly}
                      setShowTestScreen={setShowTestScreen}
                      setShowStartScreen={setShowStartScreen}
                      setCurrentLevel={setCurrentLevel}
                      setRunStartTest={setRunStartTest}
                    />
                  )}
                  {showFinishScreen && (
                    <FinishScreen
                      pageIsRendered={pageIsRendered}
                      assesmentGamesetInfo={assesmentGamesetInfo}
                      setAssesmentRunGameObj={setAssesmentRunGameObj}
                      Game={Game}
                      setViewOnly={setViewOnly}
                      setShowTestScreen={setShowTestScreen}
                      setShowStartScreen={setShowStartScreen}
                      setShowFinishScreen={setShowFinishScreen}
                      setRunStartTest={setRunStartTest}
                      teacherView={props.teacherView}
                      teacher={props.teacher}
                      gameScore={gameScore}
                      puzzleScore={puzzleScore}
                      pythonScore={pythonScore}
                    />
                  )}
                </>
              )}
            </>
          )}
          {showErrorFeedbackBox && (
            <ErrorBox
              showError={showErrorFeedbackBox}
              currentItem={"assessment"}
              closeErrorBoxHandler={() => {
                setShowErrorFeedbackBox(false);
                setShowBlocksExceedMsg(false);
              }}
              errorMsg={
                showBlocksExceedMsg
                  ? "You found the solution but you have used more blocks than expected"
                  : "Something’s not right!"
              }
            />
          )}
          {showBadJobFeedbackBox && (
            <BadJobFeedbackBox
              visibility={showBadJobFeedbackBox}
              setVisibility={setShowBadJobFeedbackBox}
              showFeedback={showFeedback}
              handleItemLikedFeedback={handleItemLikedFeedback}
              handleItemDislikedFeedback={handleItemDislikedFeedback}
              currentItem={"assessment"}
              nextBtnOnClickhandler={onwards}
              goToNext={props.goToNext}
              Finish={currentLevel === data.numLevels}
            />
          )}
          {showGoodJobFeedbackBox && (
            <GoodJobFeedbackBox
              visibility={showGoodJobFeedbackBox}
              setVisibility={setShowGoodJobFeedbackBox}
              sucessMsg={sucessMsg}
              showFeedback={showFeedback}
              handleItemLikedFeedback={handleItemLikedFeedback}
              handleItemDislikedFeedback={handleItemDislikedFeedback}
              currentItem={"assessment"}
              nextBtnOnClickhandler={onwards}
              goToNext={props.goToNext}
              Finish={currentLevel === data.numLevels}
            />
          )}
          {showConfetti && (
            <Confetti
              style={{ position: "absolute", right: 0, margin: 0 }}
              width={assesmentDiv.current?.getBoundingClientRect().width}
              height={assesmentDiv.current?.getBoundingClientRect().height}
              tweenDuration={5000}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
