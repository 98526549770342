import React, { useContext } from "react";
import { authUserContext } from "../Contexts/AuthUser";

const options = ["A", "B", "C", "D"];

function PuzzleView(props) {
  const { user } = useContext(authUserContext);
  const {
    puzzleStrapiData,
    puzzleData,
    submitAnswer,
    isTeachModule,
    puzzle_type,
    module_type,
    displaySolution,
    currentlyClicked,
    setCurrentlyClicked,
    giveupLevel,
    answerChecked,
    explanationRef,
    disabledSubmitButton,
    setDisableSubmitButton,
    showPuzzleExplanation,
  } = props;

  function optionClicked(option) {
    if (answerChecked || puzzleData?.status === "completed") return;
    let currentlyClickedTemp = currentlyClicked;
    if (currentlyClicked === option) {
      setCurrentlyClicked(-1);
      currentlyClickedTemp = -1;
    } else {
      setCurrentlyClicked(option);
      currentlyClickedTemp = option;
    }
    if (currentlyClickedTemp === -1) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  }
  return (
    <div id='puzzle-view'>
      <div className='row'>
        <div className='col-9'>
          <h3 className='puzzle-title'>
            {module_type === "module" && puzzle_type === "assessment" ? (
              <></>
            ) : (
              <>
                {puzzleStrapiData["puzzleName"] ??
                  puzzleStrapiData["Puzzle Name"]}
              </>
            )}
          </h3>
          <p className='puzzle-question-text'>
            {(
              puzzleStrapiData["puzzleQuestion"] ??
              puzzleStrapiData["Puzzle Question"]
            )
              .split("\n")
              .map((line, index) => {
                if (
                  index ===
                  (
                    puzzleStrapiData["puzzleQuestion"] ??
                    puzzleStrapiData["Puzzle Question"]
                  ).split("\n").length -
                    1
                ) {
                  return line;
                }
                return (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                );
              })}
          </p>
        </div>
        {(puzzleStrapiData["questionImage"] ||
          puzzleStrapiData["Question Image"]) && (
          <div className='col-3'>
            <img
              className='puzzle-question-picture'
              src={
                puzzleStrapiData["questionImage"] ??
                puzzleStrapiData["Question Image"]
              }
              alt=''
            />
          </div>
        )}
      </div>
      {puzzleStrapiData.format === "picture" ? (
        <div className='d-flex puzzle-option-container justify-content-between'>
          {options.map((option, index) => {
            return (
              <div key={index} className='puzzle-card'>
                <div
                  className={`puzzle-option image-puzzle-option ${
                    answerChecked || puzzleData?.status === "completed"
                      ? ""
                      : "not-clicked"
                  } ${
                    puzzleData?.status === "completed" &&
                    puzzleStrapiData.correct === option
                      ? "correct-option"
                      : ""
                  } ${
                    currentlyClicked === index &&
                    puzzleData?.status !== "completed"
                      ? "focussed"
                      : ""
                  } ${
                    currentlyClicked === index &&
                    puzzleData?.status === "completed" &&
                    puzzleStrapiData.correct !== option
                      ? "wrong-option"
                      : ""
                  }`}
                  onClick={() => {
                    optionClicked(index);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img
                    data-option={option}
                    alt=''
                    src={
                      option === "A"
                        ? puzzleStrapiData?.options?.optionA ??
                          puzzleStrapiData?.Options?.OptionA
                        : option === "B"
                        ? puzzleStrapiData?.options?.optionB ??
                          puzzleStrapiData?.Options?.OptionB
                        : option === "C"
                        ? puzzleStrapiData?.options?.optionC ??
                          puzzleStrapiData?.Options?.OptionC
                        : puzzleStrapiData?.options?.optionD ??
                          puzzleStrapiData?.Options?.OptionD
                    }
                  />
                  <div
                    className={`option-letter ${
                      puzzleData?.status === "completed" &&
                      puzzleStrapiData.correct === option
                        ? "correct-option"
                        : ""
                    } ${
                      currentlyClicked === index &&
                      puzzleData?.status === "completed" &&
                      puzzleStrapiData.correct !== option
                        ? "image-wrong-option"
                        : ""
                    }`}
                  >
                    ({option})
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className='d-flex flex-column puzzle-option-text-container'>
          {options.map((option, index) => {
            return (
              <div
                data-option={option}
                key={index}
                className={`puzzle-option-text text-puzzle-option ${
                  answerChecked || puzzleData?.status === "completed"
                    ? ""
                    : "not-clicked"
                } ${
                  currentlyClicked === index &&
                  puzzleData?.status !== "completed"
                    ? "focussed"
                    : ""
                } ${
                  puzzleData?.status === "completed" &&
                  puzzleStrapiData.correct === option
                    ? "correct-option"
                    : ""
                } ${
                  currentlyClicked === index &&
                  puzzleData?.status === "completed" &&
                  puzzleStrapiData.correct !== option
                    ? "wrong-option"
                    : ""
                } ${module_type !== "module" ? "notebook-puzzle-option" : ""}`}
                onClick={() => {
                  optionClicked(index);
                }}
              >
                ({option}){" "}
                {puzzleStrapiData?.options?.[`option${option}`] ??
                  puzzleStrapiData?.Options?.[`Option${option}`]}
              </div>
            );
          })}
        </div>
      )}
      {!showPuzzleExplanation && puzzleData?.status !== "completed" ? (
        <div className='row'>
          <div
            className='col d-flex justify-content-between'
            style={{ flex: "1" }}
          >
            <div className='row' />
            <button
              className='puzzle-submit-button'
              disabled={disabledSubmitButton}
              onClick={submitAnswer}
            >
              Submit
            </button>
            <div className='row show-puzzle-solution-container'>
              {isTeachModule && (
                <button
                  id='show-puzzle-solution'
                  style={{
                    fontSize: "1.5vw",
                    marginTop: "0.3vw",
                    lineHeight: "2.2vw",
                  }}
                  onClick={displaySolution}
                >
                  Show solution
                </button>
              )}
              {puzzle_type === "assessment" &&
                user?.role?.includes("student") &&
                puzzleData?.status !== "completed" && (
                  <button
                    className='giveup-button ml-auto'
                    onClick={giveupLevel}
                  >
                    Skip Level
                  </button>
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className='explanation-box' ref={explanationRef}>
          <p className='puzzle-explanation'>Explanation</p>
          <div className='row'>
            <div
              className={
                puzzleStrapiData?.Explanation?.Image ||
                puzzleStrapiData?.explanation?.image
                  ? "col-9"
                  : "col-12"
              }
            >
              <p className='puzzle-explanation-text'>
                {puzzleStrapiData?.explanation?.text ??
                  puzzleStrapiData?.Explanation?.Text}
              </p>
            </div>
            {puzzleStrapiData?.Explanation?.Image ||
            puzzleStrapiData?.explanation?.image ? (
              <div className='col-3'>
                <img
                  className='puzzle-question-picture puzzle-explanation-image'
                  src={
                    puzzleStrapiData?.Explanation?.Image ??
                    puzzleStrapiData?.explanation?.image
                  }
                  alt=''
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PuzzleView;
