import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MarkdownView from "react-showdown";
import "../../Styles/Notes.css";
import { PrimaryNavBarContext } from "../Layout/Layout";

function Notes() {
  document.title = "CSSoch | Notes";
  const { grade,section, type, moduleNo, moduleId } = useParams();
  const [notes, setNotes] = useState();
  const {setNavbarModuleData, setIsModulePage, setTeacherGrade, setTeacherSection} = useContext(PrimaryNavBarContext)

  useEffect(() => {
    setIsModulePage(true)
    setTeacherGrade(grade)
    setTeacherSection(section)
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/cms/${
        type === "module" ? "modules" : "notebook-set"
      }/notes/${moduleId}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
          setNavbarModuleData({moduleName:
            `${
              type === "module"
                ? data["Module Name"]
                : data?.Notebook_Display_Name ?? data?.Notebook_Name
            }`
          }
        );
        setNotes(data.Notes);
      });
  }, []);
  return (
    <>
      <div className='notes-content ml-0 p-3'>
        {notes ? (
          <MarkdownView className='rendernotes' markdown={notes} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Notes;
