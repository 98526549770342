import { useContext, useState, useRef, useEffect } from "react";
import { Container, Grid, Stack } from "@mui/material";
import { useLocation, Link } from "react-router-dom";
import "../../Styles/Playground.css";
import ArrowLeft from "../../Assets/Images/ArrowLeft.svg";
import Textarea from "@mui/joy/Textarea";
import { authUserContext } from "../../Components/Contexts/AuthUser";
import {
  asyncRun,
  asyncConsoleRun,
  setInput,
  interruptCodeExecution,
  interruptCodeExecutionAfterTimeout,
  setPyodideLoaded,
  loadPyodide,
} from "../../Components/ModuleComponents/PyWorker";
import Console from "../../Components/PythonConsole/Console";
import GraphicPyodide from "../../Components/ModuleComponents/Python/GraphicsPyodide/graphicPyodide";
import PythonConsole from "../../Components/PythonConsole/PythonConsole";
import { formatError } from "../../Components/ModuleComponents/Python/PythonFormatError";
import { pythonMessages } from "../../Utils/Constants/PythonConstants";
import PythonGraphicsBox from "../../Components/ModuleComponents/Python/PythonGraphicsBox";

function PlaygroundPython() {
  // Ace editor configuration
  const consoleId = "exercise-console";
  const editorId = "exercise-editor";
  let editor = document.querySelector(`#${editorId}`);
  let aceEditor = window.ace.edit(editor, {
    theme: "ace/theme/cloud",
    mode: "ace/mode/python",
  });
  aceEditor.setOptions({
    enableBasicAutocompletion: true,
    enableSnippets: true,
    enableLiveAutocompletion: true,
  });

  const { user } = useContext(authUserContext);
  const location = useLocation();
  const [state, setState] = useState(location?.state);
  const [viewType, setViewType] = useState(
    state?.type ? state?.type : "create"
  );
  const [projectData, setProjectData] = useState(
    state && state?.type === "view" ? state?.projectDetails : {}
  );
  const [publishedProject, setPublishedProject] = useState(
    state?.projectDetails?.published ? true : false
  );
  const [projectDescription, setProjectDescription] = useState(
    state?.projectDetails?.description ? state?.projectDetails?.description : ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
  const [isProgramRunning, setIsProgramRunning] = useState(false);
  const [programCurrentlyRunning, setProgramCurrentlyRunning] = useState(false);
  const [preLoadedImage, setPreLoadedImage] = useState(null);
  const sendInputBtn = useRef(null);
  const inputBox = useRef(null);
  const [consoleElement, setConsoleElement] = useState(null);
  const [graphicPyodide, setGraphicPyodide] = useState(null);
  const [userGraphicCode, setUserGraphicCode] = useState(null);
  const grade = state?.grade ? state?.grade : null;
  const isGraphicMode =
    state?.projectDetails?.projectType === "pythonGraphics" ?? false;

  useEffect(async () => {
    async function fetchStudentProjectData() {
      const projectDataResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/get-playground-game-data/${state?.projectDetails?.project_id}`
      );
      const playgroundProjectData = await projectDataResponse.json();
      setProjectData(playgroundProjectData);
    }

    if (state?.type !== "view") await fetchStudentProjectData();
    else aceEditor.setValue(state?.projectDetails?.code, -1);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (isGraphicMode && !Object.hasOwn(projectData, "code")) {
        aceEditor.setValue(
          "def setup():\n    createCanvas(400, 400)\n\ndef draw():\n    # Write Your Code Here\n    background(255)",
          1
        );
      } else {
        aceEditor.setValue(projectData?.code, -1);
      }
      aceEditor?.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isGraphicMode) loadPyodide();
    let consoleObj = new Console(consoleId);
    if (isPyodideLoaded) {
      consoleObj.enable();
    }
    setConsoleElement(consoleObj);
    if (isGraphicMode) setGraphicPyodide(new GraphicPyodide(consoleObj));

    return () => {
      if (!isGraphicMode && isProgramRunning) interruptCodeExecution();
      else if (programCurrentlyRunning) stopPythonGraphicsCode();
    };
  }, []);

  useEffect(() => {
    if (!isPyodideLoaded) {
      let interval;
      interval = setInterval(() => {
        setIsPyodideLoaded(setPyodideLoaded());
      }, 200);
      return () => {
        clearInterval(interval);
      };
    }
    if (isPyodideLoaded) {
      consoleElement?.enable();
    }
  }, [isPyodideLoaded]);

  useEffect(() => {
    const runFunctionsAsync = async () => {
      if (graphicPyodide) {
        await graphicPyodide.setup();
        graphicPyodide.setOnErrorCallback((error) => {
          console.log(error);
          programCompletedRunning();
        });
        setIsPyodideLoaded(true);
      }
    };
    runFunctionsAsync();
  }, [graphicPyodide]);

  const uploadProjectData = (code) => {
    let payload = {
      project_data: {
        project_id: projectData?.project_id,
        code: code,
      },
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/upload-playground-project`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setProjectData({ ...projectData, ...data });
      });
  };

  const publishProject = (code = null) => {
    projectData.status = "Active";
    projectData.published = true;
    if (code) {
      projectData.code = code;
    }
    const payload = {
      project_data: projectData,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/publish-playground-project`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setProjectData({ ...projectData, ...data.project_data });
        setPublishedProject(true);
      });
  };

  const unPublishProject = () => {
    const payload = {
      project_id: projectData.project_id,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/unpublish-playground-project`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setProjectData(data);
        setPublishedProject(false);
      });
  };

  const saveProjectDescription = () => {
    projectData.description = projectDescription;
    if (projectData.published) {
      publishProject();
    } else {
      const payload = {
        project_data: {
          project_id: projectData.project_id,
          description: projectDescription,
        },
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/upload-playground-project`,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          setProjectData({ ...projectData, ...data });
        });
    }
  };

  function setOutputToConsole(output, errorOccurred = false) {
    consoleElement.addMessage(output + "\n", errorOccurred);
  }

  function resetCode() {
    aceEditor.setValue("", -1);
  }

  const runCode = async () => {
    clearConsoleHandler();
    setIsProgramRunning(true);
    const prog = aceEditor.getValue();
    if (viewType !== "view") {
      if (projectData.published) {
        // while publishing the project we are updating data of the project in community projects and in playground projects
        publishProject(prog);
      } else {
        uploadProjectData(prog);
      }
    }
    try {
      inputBox.current.value = "";
      const { results, error, interrupted } = await asyncRun(prog, consoleId);
      console.log(
        `results: ${results}, error: ${error}, interrupted: ${interrupted}`
      );
      if (results) {
        setOutputToConsole(results);
      } else if (error) {
        setOutputToConsole(
          formatError(
            error,
            false,
            prog,
            pythonMessages.interruptExecutionMessage,
            pythonMessages.inputDisabledInConsoleMessage
          ),
          true
        );
      }
    } catch (e) {
      setOutputToConsole(
        formatError(
          e,
          false,
          prog,
          pythonMessages.interruptExecutionMessage,
          pythonMessages.inputDisabledInConsoleMessage
        ),
        true
      );
    }
    setIsProgramRunning(false);
  };

  // Python Graphics Code Start Here
  function programCompletedRunning() {
    if (inputBox.current.value.trim() === "")
      sendInputBtn.current.disabled = true;
    else sendInputBtn.current.disabled = false;
    inputBox.current.disabled = false;
    setProgramCurrentlyRunning(false);
  }

  function loadGraphicType(graphicType) {
    graphicPyodide.setGraphicType(graphicType);
  }

  function runPythonGraphicCode() {
    if (programCurrentlyRunning) return;
    let userCode = aceEditor.getValue();
    programRunning(userCode);
    consoleElement?.clear();
    setUserGraphicCode(userCode);
    graphicPyodide.runCode(userCode, "playground");
    if (viewType !== "view") {
      if (projectData.published) {
        publishProject(userCode);
      } else {
        uploadProjectData(userCode);
      }
    }
  }

  function stopPythonGraphicsCode() {
    if (!programCurrentlyRunning) return;
    graphicPyodide.stopExecution();
  }

  function programRunning(userCode = userGraphicCode) {
    sendInputBtn.current.disabled = true;
    inputBox.current.disabled = true;
    setProgramCurrentlyRunning(true);
  }

  // Python Console Code
  function handleInput(inputElement) {
    if (programCurrentlyRunning) return;
    let command = inputElement.value;
    if (isGraphicMode) {
      if (command.trim() === "") return;
      inputElement.value = "";
      if (command == "") return;
      consoleElement.addCommand(command);
      graphicPyodide.evaluateConsoleCode(command);
    } else {
      if (isProgramRunning) {
        inputElement.value = "";
        setInput(command);
      } else {
        if (command.trim() === "") return;
        inputElement.value = "";
        if (command === "") return;
        consoleElement.addCommand(command);
        evaluateTextConsoleCode(command);
      }
    }
  }
  async function evaluateTextConsoleCode(command) {
    try {
      if (command.trim() === "") return;
      if (command === "") return;
      setTimeout(interruptCodeExecutionAfterTimeout, 3000);
      const { output, error } = await asyncConsoleRun(command);
      console.log(output, error);
      if (output) {
        setOutputToConsole(output);
      } else if (error) {
        setOutputToConsole(
          formatError(
            error,
            true,
            command,
            pythonMessages.interruptExecutionMessage,
            pythonMessages.inputDisabledInConsoleMessage,
            pythonMessages.timeoutMessage
          ),
          true
        );
      }
    } catch (error) {
      setOutputToConsole(
        formatError(
          error,
          true,
          command,
          pythonMessages.interruptExecutionMessage,
          pythonMessages.inputDisabledInConsoleMessage,
          pythonMessages.timeoutMessage
        ),
        true
      );
    }
  }
  const consoleInputKeyDownHandler = (event) => {
    if (event.key === "Enter") {
      sendInputBtn.current.disabled = true;
      handleInput(inputBox.current);
    }
  };
  const consoleInputChangeHandler = () => {
    if (inputBox.current.value.trim() === "") {
      sendInputBtn.current.disabled = true;
    } else {
      sendInputBtn.current.disabled = false;
    }
  };
  const consoleSendBtnClickHandler = () => {
    sendInputBtn.current.disabled = true;
    handleInput(inputBox.current);
  };
  const clearConsoleHandler = () => {
    consoleElement?.clear();
  };

  return (
    <Container className="playground-container">
      <div
        className="playground-content"
        style={isGraphicMode ? { minHeight: "600px" } : {}}
      >
        <div className="playground-content-wrapper">
            <div id="playground-container" className="actions-wrapper">
            <div
              style={{ width: "calc(100% - 400px)", display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
              <Link
                to={
                  user.role.includes("teacher") ? `/community/${grade}` : "/home"
                }
                className="return-community-button"
                state={{ tab: "community" }}
                onClick={() => {
                  // Clearup function when going back
                }}
              >
                <span className="playground-return-home d-flex align-items-center">
                  <img src={ArrowLeft} alt="" />
                  <span className="back-text">Back</span>
                </span>
              </Link>
            <h1 className="game-set-name" style={{padding:"0 2vw"}}>{state?.projectDetails?.title}</h1>
            <Stack direction="row" spacing={2}>
              <button
                className="reset-code"
                style={{ lineHeight: "2.2vw", backgroundColor: "#aecb2a" }}
                onClick={() =>
                  isGraphicMode ? runPythonGraphicCode() : runCode()
                }
                disabled={
                  !isPyodideLoaded ||
                  isProgramRunning ||
                  programCurrentlyRunning
                }
              >
                Run
              </button>
              <button
                disabled={
                  isGraphicMode
                    ? !programCurrentlyRunning
                    : !isProgramRunning
                }
                className="reset-code"
                style={{ lineHeight: "2.2vw", backgroundColor: "red", margin: "0 16px" }}
                onClick={() =>
                  isGraphicMode
                    ? stopPythonGraphicsCode()
                    : interruptCodeExecution()
                }
              >
                Stop
              </button>
              
            </Stack>
            </div >
          <div 
            item
            style={{ width: "400px" }}
          >
            { viewType !== "view" ? (
              <Stack style={{ justifyContent: "end", alignItems: "end"}}>
                <button
                  id="python-publish-button"
                  disabled={!projectData?.project_id}
                  onClick={
                    publishedProject
                      ? unPublishProject
                      : () => {
                          publishProject();
                        }
                  }
                >
                  {projectData?.published ? "Unshare" : "Share"}
                </button>
              </Stack>
              ) : (
                <p className="student-name" style={{textAlign: "end"}}>
                  <span
                    style={{ fontWeight: 400, fontFamily: "rajdhani-medium"
                  }}
                  >
                    Created By:{" "}
                  </span>
                  {projectData?.student_name ? projectData?.student_name : ""},{" "}
                  {projectData?.section ? projectData?.section : ""}
                </p>
            )}
          </div>
          </div>
          <div
            style={{ height: "90%", display: "flex", paddingBottom: "1.5vw", marginTop:"1vh"}}
          >
            <Grid
              item
              xs={!isGraphicMode ? 6 : ""}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
              className="playground-main-container"
            >
              <div style={{ display: "flex", height: "100%" }}>
                <div
                  style={{ width: "calc(100% - 400px)" }}
                >
                  <div className="content-tab" style={{ height: "100%" }}>
                    <div
                      className="playground-python-editor-container"
                    >
                      <div id={editorId} className="editor"></div>
                    </div>
                  </div>
                </div>
                <Grid item style={{ height: "100%", width: "400px" }}>
                  <div
                    className="graphic-window-panel playground-graphic-window-panel"
                    style={{ height: "100%", width: "400px" }}
                  >
                    {isGraphicMode ? (
                      <PythonGraphicsBox
                        sketchHolderId="playground-sketch-holder"
                        preLoadedImage={preLoadedImage}
                        programCurrentlyRunning={programCurrentlyRunning}
                      />
                    ) : (
                      <></>
                    )}
                    <div className="graphic-console">
                      <PythonConsole
                        inputBoxRef={inputBox}
                        sendButtonRef={sendInputBtn}
                        onClearConsole={clearConsoleHandler}
                        inputBoxOnKeyDown={consoleInputKeyDownHandler}
                        inputBoxOnChange={consoleInputChangeHandler}
                        onSendButtonClick={consoleSendBtnClickHandler}
                        consoleId={consoleId}
                      />
                    </div>
                  </div>
                </Grid>
              </div>
            </Grid>
          </div>

          <div className="project-description-container">
            <div className="project-description-header">
              <p className="project-description-title">Project Description </p>
            </div>
            <Textarea
              color="neutral"
              minRows={3}
              size="lg"
              variant="outlined"
              value={
                projectDescription
                  ? projectDescription
                  : viewType !== "view"
                  ? projectDescription
                  : "No Description"
              }
              onChange={(e) => {
                if (e.target.value.length < 300)
                  setProjectDescription(e.target.value);
              }}
              disabled={viewType === "view"}
            />
            {viewType !== "view" && (
              <button
                id="description-save-button"
                onClick={saveProjectDescription}
              >
                Save Description
              </button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default PlaygroundPython;
