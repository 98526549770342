import { useState, useEffect, useRef } from "react";
import {
  asyncRun,
  asyncConsoleRun,
  setInput,
  interruptCodeExecution,
  interruptCodeExecutionAfterTimeout,
  setPyodideLoaded,
  asyncRunTest,
  loadPyodide,
  clearNamespaces,
} from "./PyWorker";
import { Container, Grid, Stack, styled, Tooltip, tooltipClasses } from "@mui/material";
import GraphicPyodide from "./Python/GraphicsPyodide/graphicPyodide";
import PythonConsole from "../PythonConsole/PythonConsole";
import Console from "../PythonConsole/Console";
import PythonInstruction from "./Python/PythonInfo/PythonInstruction";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import {
  pythonMessages,
  testingCode,
} from "../../Utils/Constants/PythonConstants";
import { formatError } from "./Python/PythonFormatError";
import LevelButtons from "./Python/LevelButtons";
import GoodJobFeedbackBox from "./FeedbackBox/GoodJobFeedbackBox";
import PlayVideo from "./PlayVideo";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import PythonGraphicsBox from "./Python/PythonGraphicsBox";

const CollapsibleInstructionGrid = styled(Grid)(({ theme, expanded }) => ({
  overflow: 'hidden',
  transition: 'max-width 0.3s ease, opacity 0.3s ease',
  maxWidth: expanded ? '50%' : '0',
  opacity: expanded ? 1 : 0,
  [theme.breakpoints.down('sm')]: {
    maxWidth: expanded ? '100%' : '0',
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily:"rajdhani-medium",
  },
}));

export default function Python(props) {
  const [isRendredOnce, setIsRenderedOnce] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [title, setTitle] = useState("");
  const [preCode, setPreCode] = useState("#Write your code here");
  const [instruction, setInstruction] = useState("Instruction");
  const [conceptDescription, setConceptDescription] = useState(
    "Concept Description"
  );
  const [currentLevelJsonConfig, setCurrentLevelJsonConfig] = useState(null);
  const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
  const [isProgramRunning, setIsProgramRunning] = useState(false);
  const [isGraphicMode, setIsGraphicMode] = useState(false);
  const [showTestCase, setShowTestCase] = useState();
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [consoleElement, setConsoleElement] = useState(null);
  const [graphicPyodide, setGraphicPyodide] = useState(null);
  const [programCurrentlyRunning, setProgramCurrentlyRunning] = useState(false);
  const [userGraphicCode, setUserGraphicCode] = useState(null);
  const [pythonGraphicsSetupCompleted, setPythonGraphicsSetupCompleted] =
    useState(false);
  const [showGoodJobFeedbackBox, setShowGoodJobFeedbackBox] = useState(false);
  const [preLoadedImage, setPreLoadedImage] = useState(null);
  const [isPythonStatisticsSetup, setIsPythonStatisticsSetup] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const sendInputBtn = useRef(null);
  const inputBox = useRef(null);
  const testingTimeout = useRef(null);
  const pythonContainerRef = useRef(null);
  const isCurrentSectionPython = useRef(props.currentSection === "python" ? true : false);
  const pythonGraphicsMode = props.strapiData.Python[0].Graphics;
  const teacherSeeingStudentWork = props.teacher && !props.teacherView;
  const consoleId = "exercise-console";
  const editorId = "exercise-editor";
  const [helpVideoPopup, setHelpVideoPopup] = useState(false);

  useEffect(() => {
    async function initPython() {
      if (props.currentSection === "python") {
        isCurrentSectionPython.current = true;
        props.teacher && !props.teacherView
          ? (document.title = "CSSoch | Review Python")
          : (document.title = "CSSoch | Python");
        if (!isRendredOnce) {
          if (!pythonGraphicsMode) loadPyodide();
          setIsGraphicMode(props.strapiData?.Python[0].Graphics);
          await setupPythonStatistics();
          let consoleObj = new Console(consoleId);
          if (isPyodideLoaded) {
            consoleObj.enable();
          }
          setConsoleElement(consoleObj);
          if (pythonGraphicsMode) {
            setGraphicPyodide(new GraphicPyodide(consoleObj));
          }
          setIsRenderedOnce(true);
        }
      } else {
        isCurrentSectionPython.current = false;
        setShowGoodJobFeedbackBox(false);
        if (!pythonGraphicsMode && isProgramRunning) interruptCodeExecution();
        else if (programCurrentlyRunning) stopPythonGraphicsCode();
      }
      
    }

    initPython();

  }, [props.currentSection]);
  useEffect(() => {
    if (isPythonStatisticsSetup) {
      setupPythonLevelButtons();
    }
  }, [isPythonStatisticsSetup]);

  useEffect(() => {
    if (!isPyodideLoaded) {
      let interval;
      interval = setInterval(() => {
        setIsPyodideLoaded(setPyodideLoaded());
      }, 200);
      return () => {
        clearInterval(interval);
      };
    }
    if (isPyodideLoaded) {
      consoleElement?.enable();
    }
  }, [isPyodideLoaded]);

  useEffect(() => {
    const runFunctionsAsync = async () => {
      if (graphicPyodide) {
        await graphicPyodide.setup();
        graphicPyodide.setOnErrorCallback((error) => {
          console.log(error);
          if (
            error !==
              "Exception: " + pythonMessages.interruptExecutionMessage &&
            !teacherSeeingStudentWork
          ) {
            let pythonLevels = [...props.statusStorage.python_levels];
            if (pythonLevels[currentLevel - 1]?.status !== "completed") {
              pythonLevels[currentLevel - 1].test_cases =
                pythonMessages.compilationErrorMsg;
              props.updateStatusStorage({ python_levels: pythonLevels });
            }
          }
          programCompletedRunning();
        });
        setIsPyodideLoaded(true);
        setPythonGraphicsSetupCompleted(true);
      }
    };
    runFunctionsAsync();
  }, [graphicPyodide]);

  useEffect(() => {
    function triggerLoadLevel() {
      if(pythonGraphicsMode && !pythonGraphicsSetupCompleted) return;
      if (props.currentSection === "python" && currentLevel !== null) {
        loadLevel(currentLevel);
      }
    }

    triggerLoadLevel();
  }, [currentLevel, pythonGraphicsSetupCompleted]);

  useEffect(() => {
    if (scrollToBottom && pythonContainerRef.current && showTestCase?.length) {
      pythonContainerRef.current.scrollTop =
        pythonContainerRef.current.scrollHeight;
      setScrollToBottom(false);
    }
  }, [scrollToBottom, showTestCase]);

  // Helper function
  async function setupPythonStatistics() {
    if (!props.statusStorage.python_levels.length) {
      const pythonLevels = [];
      for (let i = 0; i < props.strapiData.Python[0].Levels.length; i++) {
        pythonLevels.push({
          attempts: 0,
          code: "",
          test_cases: [],
          status: "incomplete",
        });
      }
      props.updateStatusStorage(
        { python_levels: pythonLevels },
        "python_levels"
      );
    }
    setIsPythonStatisticsSetup(true);
  }

  function setupPythonLevelButtons() {
    let currentLvl = 1;
    for (let i = 0; i < props.strapiData.Python[0].Levels.length; i++) {
      if (props.statusStorage.python_levels[i]?.status === "ongoing") {
        currentLvl = i + 1;
      }
    }
    if (
      currentLvl === 1 &&
      props.statusStorage.python_levels[0]?.status !== "ongoing"
    ) {
      for (let i = 0; i < props.statusStorage.python_levels.length; i++) {
        if (props.statusStorage.python_levels[i]?.status === "incomplete") {
          currentLvl = i + 1;
          break;
        }
      }
    }
    const pythonLevelsStatus = [...props.statusStorage.python_levels];
    if (
      pythonLevelsStatus[currentLvl - 1]?.status !== "ongoing" &&
      pythonLevelsStatus[currentLvl - 1]?.status !== "completed"
    ) {
      if (!teacherSeeingStudentWork) {
        pythonLevelsStatus[currentLvl - 1].status = "ongoing";
        props.updateStatusStorage({ python_levels: pythonLevelsStatus });
      }
    }
    setCurrentLevel(currentLvl);
  }

  async function loadLevel(level = currentLevel) {
    if (isProgramRunning) interruptCodeExecution();
    if (programCurrentlyRunning) stopPythonGraphicsCode();
    setConceptDescription();
    setInstruction();
    pythonContainerRef.current.scrollTop = 0;
    setShowTestCase(undefined);
    setShowGoodJobFeedbackBox(false);
    let graphicType;
    await fetch(props.strapiData.Python[0]?.Levels[level - 1]["Config File"])
      .then((res) => res.json())
      .then((result) => {
        graphicType = result.graphicType;
        setCurrentLevelJsonConfig(result);
      });
    setTitle(props.strapiData.Python[0].Levels[level - 1].Title);
    if(props.strapiData.Python[0].Levels[level - 1].DemoLevel)
      setPreCode(props.strapiData.Python[0].Levels[level - 1].Solution);
    else setPreCode(props.strapiData.Python[0].Levels[level - 1].Pre_loaded_code);
    setInstruction(props.strapiData.Python[0].Levels[level - 1].Hint);
    setConceptDescription(
      props.strapiData.Python[0].Levels[level - 1]["Problem Description"]
    );
    if (pythonGraphicsMode) {
      setPreLoadedImage(
        props.strapiData.Python[0].Levels[level - 1]["Pre Loaded Image"]
      );
    }
    if (props.statusStorage.python_levels[level - 1].code)
      aceEditor.setValue(props.statusStorage.python_levels[level - 1].code, -1);
    else
      aceEditor.setValue(
        props.strapiData.Python[0].Levels[level - 1].DemoLevel ? props.strapiData.Python[0].Levels[level - 1].Solution : props.strapiData.Python[0].Levels[level - 1].Pre_loaded_code,
        -1
      );
    aceEditor.focus();
    aceEditor.gotoLine(1);
    aceEditor.scrollToLine(0, true, true);
    let pythonLevels = [...props.statusStorage.python_levels];
    if (
      !teacherSeeingStudentWork &&
      props.statusStorage?.python_status !== "completed" &&
      pythonLevels[level - 1]?.status !== "ongoing"
    ) {
      for (let i = 0; i < pythonLevels.length; i++) {
        if (pythonLevels[i]?.status !== "completed") {
          pythonLevels[i].status = "incomplete";
        }
      }
      if (
        pythonLevels[level - 1]?.status !== "completed" &&
        pythonLevels[level - 1]?.status !== "ongoing"
      ) {
        pythonLevels[level - 1].status = "ongoing";
        props.updateStatusStorage({ python_levels: pythonLevels });
      }
    }
    if (pythonGraphicsMode) {
      loadGraphicType(graphicType);
    } else {
      clearNamespaces();
    }
    clearConsoleHandler();
    const codeElements = pythonContainerRef.current.querySelectorAll("code");
    codeElements.forEach((codeElement) => {
      codeElement.classList.add(`language-python`);
    });
    Prism.highlightAll();
  }

  function checkIfPythonIsCompleted(statuses) {
    if (teacherSeeingStudentWork) return;
    if (props.currentSection !== "python") return;
    let pythonCompleted = true;
    props.strapiData.Python[0].Levels.forEach((element, index) => {
      if (statuses.python_levels[index]?.status !== "completed") {
        pythonCompleted = false;
      }
    });
    if (pythonCompleted) return "completed";
    else if (statuses.python_status === "incomplete") return "ongoing";
    return statuses.python_status;
  }

  // Python IDE Related Functions
  let editor = document.querySelector(`#${editorId}`);
  let aceEditor = window.ace.edit(editor, {
    theme: "ace/theme/cloud",
    mode: "ace/mode/python",
  });
  aceEditor.setOptions({
    enableBasicAutocompletion: true,
    enableSnippets: true,
    enableLiveAutocompletion: true,
  });

  function setOutputToConsole(output, errorOccurred = false) {
    consoleElement.addMessage(output + "\n", errorOccurred);
  }

  function resetCode() {
    aceEditor.setValue(preCode, -1);
  }

  const runCode = async () => {
    clearConsoleHandler();
    setShowTestCase(undefined);
    setIsProgramRunning(true);
    const prog = aceEditor.getValue();
    try {
      inputBox.current.value = "";
      const { results, error, interrupted } = await asyncRun(prog, consoleId);
      console.log(
        `results: ${results}, error: ${error}, interrupted: ${interrupted}`
      );
      if (results) {
        setOutputToConsole(results);
      } else if (error) {
        setShowTestCase(undefined);
        setOutputToConsole(
          formatError(
            error,
            false,
            prog,
            pythonMessages.interruptExecutionMessage,
            pythonMessages.inputDisabledInConsoleMessage
          ),
          true
        );
      }
      let testingResults;
      let testCases = [];
      if (results !== undefined) {
        setScrollToBottom(true);
        ({ testingResults } = await asyncRunTest(
          prog,
          results,
          currentLevelJsonConfig,
          testingCode
        ));
      }
      // Checking if the level is success or failure
      let success = true;
      if (testingResults === undefined) {
        success = false;
      } else {
        for (let item of testingResults) {
          let testCase = Object.fromEntries([...item]);
          testCases.push(testCase);
          if (!("result" in testCase) || testCase.result === "fail")
            success = false;
        }
      }
      if(testCases.length) setExpanded(true);
      setShowTestCase(testCases);
      let statuses = { ...props.statusStorage };
      if (!teacherSeeingStudentWork && statuses.python_status !== "completed" && isCurrentSectionPython.current) {
        if (statuses.python_levels[currentLevel - 1]?.status !== "completed") {
          statuses.python_levels[currentLevel - 1].code = prog;
          statuses.python_levels[currentLevel - 1].attempts += 1;
          if (testingResults) {
            statuses.python_levels[currentLevel - 1].test_cases = testCases;
          } else if (interrupted === false) {
            statuses.python_levels[currentLevel - 1].test_cases =
              pythonMessages.compilationErrorMsg;
          }
          // Upadate Status
          if (success) {
            statuses.python_levels[currentLevel - 1].status = "completed";
            statuses["exercise_python_levels_completed"]++;
            if (!statuses.total_python_levels_completed)
              statuses["total_python_levels_completed"] = 1;
            else statuses["total_python_levels_completed"]++;
            statuses.python_status = checkIfPythonIsCompleted(statuses);
          }
          props.updateStatusStorage(statuses);
        }
        if(success) setShowGoodJobFeedbackBox(true);
      } else if (!teacherSeeingStudentWork && success && isCurrentSectionPython.current) {
        setShowGoodJobFeedbackBox(true);
      }
    } catch (e) {
      setOutputToConsole(
        formatError(
          e,
          false,
          prog,
          pythonMessages.interruptExecutionMessage,
          pythonMessages.inputDisabledInConsoleMessage
        ),
        true
      );
    }
    setIsProgramRunning(false);
  };

  // Python Graphics Code Start Here
  function programCompletedRunning() {
    clearTimeout(testingTimeout.current);
    testingTimeout.current = null;
    if (inputBox.current.value.trim() === "")
      sendInputBtn.current.disabled = true;
    else sendInputBtn.current.disabled = false;
    inputBox.current.disabled = false;
    setProgramCurrentlyRunning(false);
  }

  function loadGraphicType(graphicType) {
    graphicPyodide.setGraphicType(graphicType);
  }

  function runPythonGraphicCode() {
    setShowTestCase(undefined);
    if (programCurrentlyRunning) return;
    let userCode = aceEditor.getValue();
    programRunning(userCode);
    consoleElement?.clear();
    setUserGraphicCode(userCode);
    graphicPyodide.runCode(userCode,"exercise");
    let pythonLevels = [...props.statusStorage.python_levels];
    if (pythonLevels[currentLevel - 1]?.status !== "completed") {
      pythonLevels[currentLevel - 1].code = userCode;
      pythonLevels[currentLevel - 1].attempts++;
      props.updateStatusStorage({ python_levels: pythonLevels });
    }
  }

  function stopPythonGraphicsCode() {
    if (!programCurrentlyRunning) return;
    graphicPyodide.stopExecution();
  }

  function programRunning(userCode = userGraphicCode) {
    testingTimeout.current = setTimeout(() => {
      runTests(userCode);
    }, 3000);
    sendInputBtn.current.disabled = true;
    inputBox.current.disabled = true;
    setProgramCurrentlyRunning(true);
  }

  function runTests(codeToCheck) {
    let testResults = graphicPyodide.runTests(
      codeToCheck,
      currentLevelJsonConfig
    );
    let statuses = { ...props.statusStorage };
    let testCases = [];
    if (testResults !== undefined) {
      let success = true;
      if (testResults === undefined) {
        success = false;
      } else {
        for (let item of testResults) {
          let testCase = Object.fromEntries([...item]);
          testCases.push(testCase);
          if (!("result" in testCase) || testCase.result === "fail")
            success = false;
        }
      }
      if(testCases.length) setExpanded(true);
      setShowTestCase(testCases);
      setScrollToBottom(true);
      if (!teacherSeeingStudentWork && statuses.python_status !== "completed" && isCurrentSectionPython.current) {
        if (statuses.python_levels[currentLevel - 1]?.status !== "completed") {
          statuses.python_levels[currentLevel - 1].test_cases = testCases;
          //Update Status
          if (success) {
            statuses.python_levels[currentLevel - 1].status = "completed";
            if (!statuses.total_python_levels_completed)
              statuses["total_python_levels_completed"] = 1;
            else statuses["total_python_levels_completed"]++;
            statuses["exercise_python_levels_completed"]++;
            statuses.python_status = checkIfPythonIsCompleted(statuses);
          }
          props.updateStatusStorage(statuses);
        }
        if (success) setShowGoodJobFeedbackBox(true);
      } else if (!teacherSeeingStudentWork && success && isCurrentSectionPython.current) {
        setShowGoodJobFeedbackBox(true);
      }
    }
  }

  // Python Console Code
  function handleInput(inputElement) {
    if (programCurrentlyRunning) return;
    let command = inputElement.value;
    if (pythonGraphicsMode) {
      if (command.trim() === "") return;
      inputElement.value = "";
      if (command == "") return;
      consoleElement.addCommand(command);
      graphicPyodide.evaluateConsoleCode(command);
    } else {
      if (isProgramRunning) {
        inputElement.value = "";
        setInput(command);
      } else {
        if (command.trim() === "") return;
        inputElement.value = "";
        if (command === "") return;
        consoleElement.addCommand(command);
        evaluateTextConsoleCode(command);
      }
    }
  }
  async function evaluateTextConsoleCode(command) {
    try {
      if (command.trim() === "") return;
      if (command === "") return;
      setTimeout(interruptCodeExecutionAfterTimeout, 3000);
      const { output, error } = await asyncConsoleRun(command);
      console.log(output, error);
      if (output) {
        setOutputToConsole(output);
      } else if (error) {
        setOutputToConsole(
          formatError(
            error,
            true,
            command,
            pythonMessages.interruptExecutionMessage,
            pythonMessages.inputDisabledInConsoleMessage,
            pythonMessages.timeoutMessage
          ),
          true
        );
      }
    } catch (error) {
      setOutputToConsole(
        formatError(
          error,
          true,
          command,
          pythonMessages.interruptExecutionMessage,
          pythonMessages.inputDisabledInConsoleMessage,
          pythonMessages.timeoutMessage
        ),
        true
      );
    }
  }
  const consoleInputKeyDownHandler = (event) => {
    if (event.key === "Enter") {
      sendInputBtn.current.disabled = true;
      handleInput(inputBox.current);
    }
  };
  const consoleInputChangeHandler = () => {
    if (inputBox.current.value.trim() === "") {
      sendInputBtn.current.disabled = true;
    } else {
      sendInputBtn.current.disabled = false;
    }
  };
  const consoleSendBtnClickHandler = () => {
    sendInputBtn.current.disabled = true;
    handleInput(inputBox.current);
  };
  const clearConsoleHandler = () => {
    consoleElement?.clear();
  };
  const loadSolution = () => {
    aceEditor.setValue(
      props.strapiData.Python[0].Levels[currentLevel - 1].Solution,
      -1
    );
    aceEditor.focus();
    aceEditor.gotoLine(1);
    aceEditor.scrollToLine(0, true, true);
  };

  return (
    <Container
      maxWidth={false}
      id="python-view"
      className="content-tab"
      style={props.currentSection === "python" ? {} : { display: "none" }}
    >
      <div className="python-drawer-box" >
        <BootstrapTooltip placement='bottom' title={expanded ? "Hide" : "Show"} sx={{zIndex: "10"}}>
          <div onClick={() => setExpanded(pre => !pre)}>
            {expanded ? <ArrowLeftIcon /> : <ArrowRightIcon /> }
          </div>
        </BootstrapTooltip>
      </div>
      {props.statusStorage.python_levels.length ? (
        <LevelButtons
          levels={props.strapiData.Python[0].Levels}
          currentLevel={currentLevel}
          statusStorage={props.statusStorage}
          setCurrentLevel={setCurrentLevel}
        />
      ) : (
        <></>
      )}
      <Grid container className="exercise-python-container">
        <CollapsibleInstructionGrid item expanded={expanded}
            className="python-instruction-container"
            sx={{ height: "100%", flex: 1 }}
          >
          <PythonInstruction
            pythonContainerRef={pythonContainerRef}
            showTestCase={showTestCase}
            title={title}
            conceptDescription={conceptDescription}
            instruction={instruction}
            type="exercise"
            strapiData={props.strapiData}
            setHelpVideoPopup={setHelpVideoPopup}
          />
        </CollapsibleInstructionGrid>
        <Grid
          item
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingLeft : expanded ? "" : "2em",
          }}
        >
          <div id="container" style={{ margin: "5px" }}>
            <Stack direction="row" spacing={2}>
              <button
                className="reset-code"
                style={{ lineHeight: "2.2vw", backgroundColor: "#aecb2a" }}
                onClick={() =>
                  isGraphicMode ? runPythonGraphicCode() : runCode()
                }
                disabled={
                  !isPyodideLoaded ||
                  isProgramRunning ||
                  programCurrentlyRunning
                }
              >
                Run
              </button>
              <button
                disabled={
                  isGraphicMode ? !programCurrentlyRunning : !isProgramRunning
                }
                className="reset-code"
                style={{ lineHeight: "2.2vw", backgroundColor: "red" }}
                onClick={() =>
                  isGraphicMode
                    ? stopPythonGraphicsCode()
                    : interruptCodeExecution()
                }
              >
                Stop
              </button>
              <button
                className="reset-code"
                style={{ lineHeight: "2.2vw" }}
                onClick={() => resetCode()}
              >
                Reset
              </button>
            </Stack>
            <Stack direction="row" spacing={2}>
              {props.teacher && props.teacherView && (
                <button
                  className="reset-code"
                  id="python-solution-button"
                  style={{ lineHeight: "2.2vw", backgroundColor: "#aecb2a" }}
                  onClick={() => loadSolution()}
                >
                  Solution
                </button>
              )}
            </Stack>
          </div>
          <div
            className="content-tab"
            style={props.currentSection === "python" ? {} : { display: "none" }}
          >
            <div
              className="editor-container"
              style={isGraphicMode ? { height: "calc(100% - 1px)" } : {}}
            >
              <div id={editorId} className="editor"></div>
            </div>
            {!isGraphicMode && (
              <div className="console-container">
                <PythonConsole
                  inputBoxRef={inputBox}
                  sendButtonRef={sendInputBtn}
                  onClearConsole={clearConsoleHandler}
                  inputBoxOnKeyDown={consoleInputKeyDownHandler}
                  inputBoxOnChange={consoleInputChangeHandler}
                  onSendButtonClick={consoleSendBtnClickHandler}
                  consoleId={consoleId}
                />
              </div>
            )}
          </div>
        </Grid>
        {isGraphicMode && (
          <Grid
            item
            className="graphic-window-panel"
            style={{ height: "100%" }}
          >
            <PythonGraphicsBox
              sketchHolderId="exercise-sketch-holder"
              preLoadedImage={preLoadedImage}
              programCurrentlyRunning={programCurrentlyRunning}
            />
            <div className="graphic-console">
              <PythonConsole
                inputBoxRef={inputBox}
                sendButtonRef={sendInputBtn}
                onClearConsole={clearConsoleHandler}
                inputBoxOnKeyDown={consoleInputKeyDownHandler}
                inputBoxOnChange={consoleInputChangeHandler}
                onSendButtonClick={consoleSendBtnClickHandler}
                consoleId={consoleId}
              />
            </div>
          </Grid>
        )}
      </Grid>
      {showGoodJobFeedbackBox && (
        <GoodJobFeedbackBox
          visibility={showGoodJobFeedbackBox}
          setVisibility={setShowGoodJobFeedbackBox}
          showFeedback={false}
          goToNext={props.goToNext}
          Finish={currentLevel === props.strapiData.Python[0].Levels.length}
          currentItem={"python"}
          handleCurrentSectionChange={props.handleCurrentSectionChange}
          getNext={props.getNext}
          nextBtnOnClickhandler={() => {
            setShowGoodJobFeedbackBox(false);
            if(currentLevel === props.strapiData.Python[0].Levels.length) return;
            setCurrentLevel(currentLevel + 1)
          }}
          sucessMsg={"You completed the level!"}
        />
      )}
      <PlayVideo 
        setHelpVideoPopup={setHelpVideoPopup} 
        videoUrl={props.strapiData.Python[0].videoUrl}
        helpVideoPopup={helpVideoPopup}
      />
    </Container>
  );
}
